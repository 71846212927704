<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <!-- 列表主体 -->
            <el-col :span="24">
                <!-- 列表内容 -->
                <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
                    <el-tab-pane label="草稿" name="tab01">
                        <el-row class="table-container">
                            <el-col>
                                <el-table
                                    v-loading="tableLoading"
                                    :data="draftListData"
                                    fit
                                    highlight-current-row
                                    style="width: 100%;"
                                    :header-cell-style="{background:'#eef1f6', color:'#606266'}"
                                    ref="queryTable"
                                    :indent="20"
                                >
                                    <el-table-column label="小程序名称" prop="sourceMiniProgram" :show-overflow-tooltip="true"></el-table-column>
                                    <el-table-column label="描述" prop="userDesc" :show-overflow-tooltip="true"></el-table-column>
                                    <el-table-column label="版本" prop="userVersion" width="150px" align="left"></el-table-column>
                                    <el-table-column label="小程序ID" prop="sourceMiniProgramAppid" width="150px" align="left"></el-table-column>
                                    <el-table-column label="更新时间" prop="createTime" width="120" align="center">
                                        <template slot-scope="{row}">
                                            <span>{{ row.createTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="操作" align="center" width="120" fixed="right" class-name="small-padding fixed-width">
                                        <template slot-scope="{row}">
                                            <el-button type="text" size="mini" icon="el-icon-edit" @click="addToTemplate(row.draftId)">加入模板</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="普通模板" name="tab02">
                        <el-row class="table-container">
                            <el-col>
                                <el-table
                                    v-loading="tableLoading"
                                    :data="tempListData"
                                    fit
                                    highlight-current-row
                                    height="calc(100vh - 300px)"
                                    style="width: 100%;"
                                    :header-cell-style="{background:'#eef1f6', color:'#606266'}"
                                    ref="queryTable"
                                    :indent="20"
                                    :default-sort = "{prop: 'userVersion', order: 'descending'}"
                                >
                                    <el-table-column label="templateId" prop="templateId" width="150px" align="left"></el-table-column>
                                    <el-table-column label="小程序名称" prop="sourceMiniProgram" :show-overflow-tooltip="true"></el-table-column>
                                    <el-table-column label="描述" prop="userDesc" :show-overflow-tooltip="true"></el-table-column>
                                    <el-table-column label="版本" prop="userVersion" width="150px" align="left"></el-table-column>
                                    <el-table-column label="小程序ID" prop="sourceMiniProgramAppid" width="150px" align="left"></el-table-column>
                                    <el-table-column label="更新时间" prop="createTime" width="120" align="center">
                                        <template slot-scope="{row}">
                                            <span>{{ row.createTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="操作" align="center" width="160" fixed="right" class-name="small-padding fixed-width">
                                        <template slot-scope="{row}">
                                            <el-button type="text" size="mini" icon="el-icon-edit" @click="toSetNew(row.templateId)">设为最新模板</el-button>
                                            <el-button type="text" size="mini" icon="el-icon-delete" @click="deleteTemplate(row.templateId)">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="标准模板" name="tab03">
                        <el-row class="table-container">
                            <el-col>
                                <el-table
                                    v-loading="tableLoading"
                                    :data="tempListData"
                                    fit
                                    highlight-current-row
                                    height="calc(100vh - 300px)"
                                    style="width: 100%;"
                                    :header-cell-style="{background:'#eef1f6', color:'#606266'}"
                                    ref="queryTable"
                                    :indent="20"
                                >
                                    <el-table-column label="templateId" prop="templateId" width="150px" align="left"></el-table-column>
                                    <el-table-column label="小程序名称" prop="sourceMiniProgram" :show-overflow-tooltip="true"></el-table-column>
                                    <el-table-column label="描述" prop="userDesc" :show-overflow-tooltip="true"></el-table-column>
                                    <el-table-column label="版本" prop="userVersion" width="150px" align="left"></el-table-column>
                                    <el-table-column label="小程序ID" prop="sourceMiniProgramAppid" width="150px" align="left"></el-table-column>
                                    <el-table-column label="更新时间" prop="createTime" width="120" align="center">
                                        <template slot-scope="{row}">
                                            <span>{{ row.createTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="操作" align="center" width="160" fixed="right" class-name="small-padding fixed-width">
                                        <template slot-scope="{row}">
                                            <el-button type="text" size="mini" icon="el-icon-edit" @click="toSetNew(row.templateId)">设为最新模板</el-button>
                                            <el-button type="text" size="mini" icon="el-icon-delete" @click="deleteTemplate(row.templateId)">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb';
    export default {
        components: {
            Breadcrumb,
        },
        filters: {
            appTypeFilter(status) {
                const statusMap = {
                    1: '订阅号',
                    2: '服务号',
                    3: '小程序',
                };
                return statusMap[status]
            },
            payWayFilter(status) {
                const statusMap = {
                    0: '线上/线下',
                    3: '仅线下',
                };
                return statusMap[status]
            },
            payWayColorFilter(status) {
                const statusMap = {
                    0: 'success',
                    3: 'warning'
                };
                return statusMap[status]
            },
            deleteStatusFilter(status) {
                const statusMap = {
                    0: '正常',
                    1: '已删除',
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'success',
                    1: 'danger'
                };
                return statusMap[status]
            },
        },
        data () {
            return {
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '小程序模板管理'
                    }
                ],
                //视频列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    deleteStatus: '0',
                },
                //视频列表数据
                draftListData: [],
                tempListData: [],
                tableLoading: false,
                //编辑、新增表单
                editDrawer: false,
                info: {
                    orderNum: 0,
                },
                activeName: 'tab01'
            };
        },
        mounted () {
            this.queryDraftList();
        },
        computed: {
        },
        methods: {
            handleClick(tab, event) {
                if (tab.name === 'tab01') {
                    this.queryDraftList();
                } else if (tab.name === 'tab02') {
                    this.queryTempList(0);
                } else if (tab.name === 'tab03') {
                    this.queryTempList(1);
                }
            },
            //获取列表
            queryDraftList: function () {
                this.tableLoading = true;
                this.apis.postForm('wx/open/mini/mgr/templateDraftList')
                    .then((res) => {
                        if (res.code === 1) {
                            this.draftListData = res.data;
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            queryTempList: function (templateType) {
                this.tableLoading = true;
                this.apis.postForm('wx/open/mini/mgr/getTemplateList?templateType=' + templateType)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tempListData = res.data;
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            deleteTemplate: function (templateId) {
                this.$confirm('确定要删除此模板？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.apis.postForm('wx/open/mini/mgr/deleteTemplate?templateId=' + templateId)
                        .then((res) => {
                            if (res.code === 1) {
                                this.queryTempList(0)
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch((err) => {
                        console.log(err)
                    });
                }).catch(() => {
                });
            },

            toAdd: function () {
                this.info = {
                    orderNum: 0
                };
                this.editDrawer = true;
                // this.$refs.editForm.resetFields();

                this.$nextTick(function() {
                    this.$refs.editor.setHtml("");
                });
            },
            saveData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    console.log(this.$refs)
                    if (valid) {
                        let url = "wx/open/app/save";
                        this.info.orgDesc = this.$refs.editor.getHtml();
                        this.info.createBy = this.appCookies.getCookieUID();
                        this.info.createByname = this.appCookies.getCookieTNAME();
                        this.info.modifyBy = this.appCookies.getCookieUID();
                        this.info.modifyByname = this.appCookies.getCookieTNAME();
                        // 保存数据
                        this.apis.postJson(url, this.info)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.editDrawer = false;
                                    this.initTable();
                                    this.$message({
                                        message: '保存成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                })
            },
            addToTemplate: function (draftId) {
                let data = {
                    draftId: draftId,
                    templateType: 0,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                // modifyDomain
                // getQrcode
                this.apis.postForm('wx/open/mini/mgr/addToTemplate', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toSetNew: function (templateId) {
                let data = {
                    miniappTemplateId: templateId,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                // modifyDomain
                // getQrcode
                this.apis.postForm('wx/open/info/updMiniappTemplateId', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>
