<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <!-- 列表主体 -->
            <el-col :span="24">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>小程序列表</span>
                        <div style="float: right; padding: 0px 0">
<!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
<!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                        </div>
                    </div>
                    <!-- 搜索查询 -->
                    <el-row class="query-container">
                        <el-col :span="24">
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-select v-model="queryParams.deleteStatus" placeholder="请选择删除状态"  style="width: 100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="正常" value="0"></el-option>
                                        <el-option label="已删除" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                                <el-button-group style="float: right">
<!--                                    <el-button class="filter-item" type="success" size="mini" round icon="el-icon-edit" @click="toAdd">新增</el-button>-->
                                </el-button-group>
                            </el-form>
                        </el-col>
                    </el-row>
                    <!-- 列表内容 -->
                    <el-row class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                highlight-current-row
                                height="calc(100vh - 300px)"
                                style="width: 100%;"
                                :header-cell-style="{background:'#eef1f6', color:'#606266'}"
                                ref="queryTable"
                                :indent="20"
                            >
                                <el-table-column label="小程序名称" prop="appName" min-width="180" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="APPID" prop="appId" width="160"></el-table-column>
                                <el-table-column label="类型" width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <span>{{ row.appType  | appTypeFilter}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="来源" width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <span>{{ row.fromType  | fromTypeFilter}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="当前版本" prop="versionNo" width="100px" align="center"></el-table-column>
                                <el-table-column label="状态" width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.appStatus | colorFilter">
                                            {{ row.appStatus  | deleteStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="信息" prop="statusMsg" min-width="100" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="所属企业" prop="companyName" min-width="150" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="创建时间" prop="modifyTime" width="120" align="center">
                                    <template slot-scope="{row}">
                                        <span>{{ row.modifyTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="320" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-view" @click="toEdit(row.appId)">基本信息</el-button>
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-refresh" @click="mgrCode(row.appId)">版本管理</el-button>
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-refresh" @click="mgrTempl(row.appId, row.orgId)">消息模板</el-button>
<!--                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-refresh-right" @click="toPublish(row.appId)">发布</el-button>-->
<!--                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-refresh-right" @click="toTestall(row.appId)">TEST</el-button>-->
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-refresh-right" @click="refreshToken(row.appId, row.refreshToken)">刷新token</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- 分页组件 -->
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <MiniInfo ref="miniInfo"></MiniInfo>
        <MiniCode ref="miniCode"></MiniCode>
        <SubTmplate ref="subTmplate"></SubTmplate>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    import MiniInfo from "@v/miniapp/components/MiniInfo";
    import MiniCode from "@v/miniapp/components/MiniCode";
    import SubTmplate from "@v/miniapp/components/SubTmplate";
    export default {
        name: 'OrgQuery',
        components: {
            Pagination,
            Breadcrumb,
            MiniCode,
            MiniInfo,
            SubTmplate
        },
        filters: {
            appTypeFilter(status) {
                const statusMap = {
                    1: '订阅号',
                    2: '服务号',
                    3: '小程序',
                };
                return statusMap[status]
            },
            fromTypeFilter(status) {
                const statusMap = {
                    'BETAREG': '试用小程序',
                    'AUTHORIZED': '授权已有',
                };
                return statusMap[status]
            },
            payWayColorFilter(status) {
                const statusMap = {
                    0: 'success',
                    3: 'warning'
                };
                return statusMap[status]
            },
            deleteStatusFilter(status) {
                const statusMap = {
                    0: '试用中',
                    1: '已认证',
                    89252: '待小程序管理员确认',
                    89251: '待法人人脸核身校验',
                    100001: '未进行身份证校验',
                    100002: '未进行人脸识别校验',
                    100003: '法人并未确认且已超时（24h）'
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    1: 'success',
                    0: 'danger'
                };
                return statusMap[status]
            },
        },
        data () {
            return {
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '小程序列表'
                    }
                ],
                //视频列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    deleteStatus: '0',
                },
                //视频列表数据
                tableData: [],
                tableLoading: false,
                //编辑、新增表单
                editDrawer: false,
                info: {
                    orderNum: 0,
                    head_image_info: {
                        head_image_url: ''
                    },
                    wx_verify_info: {
                        qualification_verify: ''
                    },
                    signature_info: {
                        signature: ''
                    }
                },
                privacy: {
                    interfaceList: [
                        {
                            apiName: '',
                            apiChName: '',
                            apiDesc: '',
                            status: '',
                            failReason: '',
                            groupName: '',
                        }
                    ]
                },
                settingCategories: {},
                allCategories: {},
                //表单验证规则
                ruleValidate: {
                    // 视频基本信息
                    orgCode: [
                        { required: true, message: '请输入机构编码', trigger: 'blur' }
                    ],
                    orgName: [
                        { required: true, message: '请输入机构名称', trigger: 'blur' }
                    ],
                    shortName: [
                        { required: true, message: '请输入机构简称', trigger: 'blur' }
                    ],
                    orgType: [
                        { required: true, message: '请选择机构类型', trigger: 'blur' }
                    ],
                    payWay: [
                        { required: true, message: '请选择收款方式', trigger: 'blur' }
                    ],
                },
                authUrl: ''
            };
        },
        mounted () {
            this.initTable();
        },
        computed: {
        },
        methods: {
            //上传图片组件的callback
            uploadOrgSealImg: function (webPath, savePath,) {
                // alert(savePath)
                this.$set(this.info, 'orgSealUrl', webPath);
                this.$set(this.info, 'orgSeal', savePath);
            },
            //获取列表
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('wx/open/app/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            toAdd: function () {
                this.info = {
                    orderNum: 0
                };
                this.editDrawer = true;
                // this.$refs.editForm.resetFields();

                this.$nextTick(function() {
                    this.$refs.editor.setHtml("");
                });
            },
            saveData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                console.log(this.$refs)
                if (valid) {
                    let url = "wx/open/app/save";
                    this.info.orgDesc = this.$refs.editor.getHtml();
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieTNAME();
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieTNAME();
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.editDrawer = false;
                                this.initTable();
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
            },
            mgrCode: function (appId) {
                this.$refs.miniCode.open(appId)
            },
            mgrTempl: function (appId, orgId) {
                this.$refs.subTmplate.open(appId, orgId)
            },
            toEdit: function (appId) {
                this.$refs.miniInfo.open(appId)
                // this.apis.get('wx/open/mini/mgr/getBasicInfo?appId=' + appId)
                //     .then((res) => {
                //         console.log(res);
                //         if (res.code === 1) {
                //             this.info = res.data;
                //             this.getOtherInfo(this.info.appid)
                //             this.editDrawer = true;
                //         } else {
                //             this.info = {}
                //         }
                //     }).catch(err => {
                //     console.log(err);
                // });

            },
            getOtherInfo(appid) {
                this.apis.get('wx/open/mini/mgr/getPrivacyInterface?appId=' + appid)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.privacy = res.data;
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
                this.apis.get('wx/open/mini/mgr/getSettingCategories?appId=' + appid)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.settingCategories = res.data;
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
                this.apis.get('wx/open/mini/mgr/getAllCategories?appId=' + appid)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.allCategories = res.data;
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toPublish: function (appId) {
                this.$confirm('确定要发布？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        appId: appId,
                        templateId: 10,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('wx/open/mini/code/publishMiniApp', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
            toTestall: function (appId) {
                let data = {
                    appId: appId,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                // modifyDomain
                // getQrcode
                this.apis.postForm('wx/open/mini/mgr/getDomain', data)
                    .then((res) => {
                        console.log(res.data);
                    }).catch(err => {
                    console.log(err);
                });
                // this.apis.postForm('wx/open/mini/mgr/modifyDomain', data)
                //     .then((res) => {
                //     }).catch(err => {
                //     console.log(err);
                // });
            },
            getPreAuthUrl: function (appId) {
                this.apis.get('/wx/open/auth/getPreAuthUrl2?bizAppid=' + appId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.authUrl = res.data;
                        } else {
                            this.$message({
                                message: res.msg,
                                type: 'error',
                                duration: 5 * 1000
                            })
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toTest: function (appId) {
                let data = {
                    appId: appId,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                // modifyDomain
                // getQrcode
                this.apis.postForm('wx/open/mini/mgr/getQrcode', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            getAuthorizerInfo: function (appId) {
                let data = {
                    appId: appId
                }
                this.apis.get('/wx/open/auth/getAuthorizerInfo2', data)
                    .then((res) => {
                        if (res.code === 1) {
                            console.log(res.data)
                        } else {
                            this.$message({
                                message: res.msg,
                                type: 'error',
                                duration: 5 * 1000
                            })
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            refreshToken: function (appId, refreshToken) {
                let data = {
                    appId: appId,
                    refreshToken: refreshToken
                };
                this.apis.get('wx/open/app/refreshToken', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            applyPrivacy: function (appId, apiName) {
                let content = "";
                if (apiName === 'wx.getFuzzyLocation') {
                    content = "学员考试签到，获取打卡位置";
                } else if (apiName === 'wx.chooseAddress') {
                    content = "学员报名后，填写邮寄地址";
                } else if (apiName === 'wx.chooseLocation') {
                    content = "学员考试签到，获取打卡位置";
                }
                let data = {
                    appId: appId,
                    apiName: apiName,
                    content: content
                };
                this.apis.postForm('wx/open/mini/mgr/applyPrivacyInterface', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.getOtherInfo(appId)
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            delCategories: function (appId, first, second) {
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>
